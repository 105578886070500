@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');

.App {
  text-align: center;
}

body {
  background-color: black;
  overflow-y:hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(0.625rem + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HeaderBar {
  width: 100%;
  height: 2.1875rem;
  background: linear-gradient(90deg, #6427E7 0%, #8177D6 100%);
  border: 0.0625rem solid #C1BDBC;
  box-sizing: border-box;
  box-shadow: inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  position: absolute;
}
.HeaderBarClosed {
  width: 100%;
  height: 2.1875rem;
  bottom: 0rem;
  background: linear-gradient(90deg, #6427E7 0%, #8177D6 100%);
  border: 0.0625rem solid #C1BDBC;
  box-sizing: border-box;
  box-shadow: inset 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  position: absolute;
  overflow: hidden;
}
.HeaderBarClosed:hover {
  cursor: pointer;
}
.BottomLeftLogo {
  width: 11.875rem;
  left: 0rem;
  bottom: -0.6875rem;
  position: absolute;
}
.TopLeftLogo {
  width: 11.875rem;
  left: 0rem;
  top: -0.6875rem;
  position: absolute;
}
.TopLeftLogo:hover {
  cursor: pointer;
}

.TopRightButton { 
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  right: 1.25rem;
  top: 0.375rem;
  background: #EDE8E7;
  border: 0.0625rem solid #2C3131;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TopRightButton:hover {
  cursor: pointer;
}

.TopRightUnion {
  position: absolute;
  width: 0.9375rem;
  height: 0.9375rem;
}

.HeaderNavRect {
  position: absolute;
  width: 100%;
  height: 1.875rem;
  left: 0rem;
  top: 2.1875rem;
  border: 0.0625rem solid #C1BDBC;
  box-sizing: border-box;
  background-color: #EDE8E7;
}

.DocsLink {
  position: absolute;
  left: 1.875rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  text-decoration-line: underline;
  color: #2C3131;
}
.AboutLink {
  position: absolute;
  left: 6.5625rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  text-decoration-line: underline;
  color: #2C3131;
}
.AboutLink:hover {
  cursor: pointer;
}
.AboutLink:active {
  background-color: #0000FF;
  color: #EEEEEE;
  box-sizing: border-box;
  border: 0.0625rem solid #0000FF;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: -0.5rem;
}
.AboutLinkClicked {
  position: absolute;
  left: 6.5625rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  text-decoration-line: underline;
  background-color: #0000FF;
  color: #EEEEEE;
  box-sizing: border-box;
  border: 0.0625rem solid #0000FF;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: -0.5rem;
}
.AboutLinkClicked:hover {
  cursor: pointer;
}
.CommunityLink {
  position: absolute;
  left: 11.6875rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  text-decoration-line: underline;
  color: #2C3131;
}
.ContactLink {
  position: absolute;
  left: 19.0625rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  text-decoration-line: underline;
  color: #2C3131;
}
.ContactLink:active {
  background-color: #0000FF;
  color: #EEEEEE;
  box-sizing: border-box;
  border: 0.0625rem solid #0000FF;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: -0.5rem;
}
.ContactLinkClicked {
  position: absolute;
  left: 19.0625rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  text-decoration-line: underline;
  background-color: #0000FF;
  color: #EEEEEE;
  box-sizing: border-box;
  border: 0.0625rem solid #0000FF;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: -0.5rem;
}
.ContactLinkClicked:hover {
  cursor: pointer;
}
.ContactLink:hover {
  cursor: pointer;
}
.ContactDropdown {
  box-sizing: border-box;
  position: absolute;
  width: 10.625rem;
  height: 11.8875rem;
  left: 19.0625rem;
  top: 4.0625rem;
  background: #D5CFCE;
  border: 0.0625rem solid #2C3131;
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin-left: -0.4375rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
}
.ContactDropdownHidden {
  visibility: hidden;
}
.ContactHeader {
  box-sizing: border-box;
  position: absolute;
  width: 8.5625rem;
  height: 2.0625rem;
  display: flex;
  justify-content: left;
  align-items: center;
  border-left: 0.0938rem solid #C1BDBC;
  border-bottom: 0.0625rem solid #C1BDBC;
  margin-left:1.9375rem;
  padding-left: 0.3125rem;
}
.ContactBody {
  box-sizing: border-box;
  position: absolute;
  width: 15.1875rem;
  height: 9.7rem;
  display: flex;
  justify-content: left;
  align-items: left;
  border-left: 0.0938rem solid #C1BDBC;
  margin-left:1.9375rem;
  margin-top: 2.0625rem;
  padding-top: 0.625rem;
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
  overflow-x:visible;
}
.AboutDropdown {
  box-sizing: border-box;
  position: absolute;
  width: 10.875rem;
  height: 6.25rem;
  left: 6.5625rem;
  top: 4.0625rem;
  background: #D5CFCE;
  border: 0.0625rem solid #2C3131;
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin-left: -0.4375rem;
}
.AboutDropdownHidden {
  visibility: hidden;
}
.AboutDropdownMenu {
  position: absolute;
  display:flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  border-left: 0.0938rem solid #C1BDBC;
  margin-left: 1.9375rem;
  height:6.125rem;
  z-index: 3;
  overflow:hidden;
}
.DropdownMenuItem {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  width:8.6875rem;
  height:auto;
  flex: 1 1 auto;
  border-top: 0.0469rem solid #C1BDBC;
  border-bottom: 0.0469rem solid #C1BDBC;
}
.DropdownMenuItem:hover {
  background-color: #C1BDBC;
  cursor: pointer;
}
.DropdownMenuItemClicked {
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  width:8.6875rem;
  height:auto;
  flex: 1 1 auto;
  background-color: #C1BDBC;
}
.DropdownMenuItemClicked:hover {
  cursor: pointer;
}
.DropdownMenuText {
  text-align: left;
  padding-left:0.375rem;
  padding-right:0.375rem;
}
.MissionDropdown {
  box-sizing: border-box;
  position: absolute;
  width: 17.25rem;
  height: 21.625rem;
  left: 17.375rem;
  top: 4.0625rem;
  background: #D5CFCE;
  border: 0.0625rem solid #2C3131;
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin-left: -0.4375rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
}
.MissionDropdownHidden {
  visibility: hidden;
}
.DropdownSpacer {
  box-sizing: border-box;
  position: absolute;
  width:1.9375rem;
  height:2.0625rem;
  border-bottom: 0.0625rem solid #C1BDBC;
}
.MissionHeader {
  box-sizing: border-box;
  position: absolute;
  width: 15.1875rem;
  height: 2.0625rem;
  display: flex;
  justify-content: left;
  align-items: center;
  border-left: 0.0938rem solid #C1BDBC;
  border-bottom: 0.0625rem solid #C1BDBC;
  margin-left:1.9375rem;
  padding-left: 0.3125rem;
}
.MissionBody {
  box-sizing: border-box;
  position: absolute;
  width: 15.1875rem;
  height: 19.4375rem;
  display: flex;
  justify-content: left;
  align-items: left;
  border-left: 0.0938rem solid #C1BDBC;
  margin-left:1.9375rem;
  margin-top: 2.0625rem;
  padding-top: 0.625rem;
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
  overflow-x:visible;
}
.BuidlersDropdown {
  box-sizing: border-box;
  position: absolute;
  width: 17.25rem;
  height: 27.5rem;
  left: 17.375rem;
  top: 6.125rem;
  background: #D5CFCE;
  border: 0.0625rem solid #2C3131;
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin-left: -0.4375rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
}
.BuidlersDropdownHidden {
  visibility: hidden;
}
.BuidlersHeader {
  box-sizing: border-box;
  position: absolute;
  width: 15.1875rem;
  height: 2.0625rem;
  display: flex;
  justify-content: left;
  align-items: center;
  border-left: 0.0938rem solid #C1BDBC;
  border-bottom: 0.0625rem solid #C1BDBC;
  margin-left:1.9375rem;
  padding-left: 0.3125rem;
}
.BuidlersBody {
  box-sizing: border-box;
  position: absolute;
  width: 15.1875rem;
  height: 25.3125rem;
  display: flex;
  justify-content: left;
  align-items: left;
  border-left: 0.0938rem solid #C1BDBC;
  margin-left:1.9375rem;
  margin-top: 2.0625rem;
  padding-top: 0.625rem;
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
  overflow-x:visible;
}
.OrgsDropdown {
  box-sizing: border-box;
  position: absolute;
  width: 17.25rem;
  height: 22.8125rem;
  left: 17.375rem;
  top: 8.1875rem;
  background: #D5CFCE;
  border: 0.0625rem solid #2C3131;
  box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin-left: -0.4375rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
}
.OrgsDropdownHidden {
  visibility: hidden;
}
.OrgsHeader {
  box-sizing: border-box;
  position: absolute;
  width: 15.1875rem;
  height: 2.0625rem;
  display: flex;
  justify-content: left;
  align-items: center;
  border-left: 0.0938rem solid #C1BDBC;
  border-bottom: 0.0625rem solid #C1BDBC;
  margin-left:1.9375rem;
  padding-left: 0.3125rem;
}
.OrgsBody {
  box-sizing: border-box;
  position: absolute;
  width: 15.1875rem;
  height: 20.625rem;
  display: flex;
  justify-content: left;
  align-items: left;
  border-left: 0.0938rem solid #C1BDBC;
  margin-left:1.9375rem;
  margin-top: 2.0625rem;
  padding-top: 0.625rem;
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
  overflow-x:visible;
}

.MenuArrow {
  height:0.625rem;
  padding-right:0.625rem;
  flex:none;
}
.MainNavbar {
  position: absolute;
  width: 100%;
  height: 3.75rem;
  left: 0rem;
  top: 4.0625rem;
  background-color: #EDE8E7;
  border: 0.0625rem solid #C1BDBC;
  box-sizing: border-box;
  box-shadow: inset 0.25rem 0.25rem 0.25rem rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  overflow-y:hidden;
}
.HeaderButton {
  width: 9.375rem;
  height: 3.75rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-decoration: none;
}
.HeaderButton:hover {
  cursor: pointer;
}
.HeaderButton:active {
  border: 0.125rem solid #C1BDBC;
  border-left: 0.0625rem solid #C1BDBC;
  border-bottom: 0.0625rem solid #C1BDBC;
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.HeaderButtonSelected {
  width: 9.375rem;
  height: 3.75rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  text-decoration: none;
  border: 0.125rem solid #C1BDBC;
  border-left: 0.0625rem solid #C1BDBC;
  border-bottom: 0.0625rem solid #C1BDBC;
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.HeaderButtonSelected:hover {
  cursor: default;
}
.HeaderIcon {
  padding-top: 0.4375rem;
  padding-bottom: 0rem;
  height: 1.25rem;
}
.HeaderIconMain {
  padding-top: 0.125rem;
  height: 2.375rem;
  margin-bottom: -0.3125rem;
}
.HeaderText {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  color: #2C3131;
}
.HeaderSpacer {
  width: 31.25rem;
}

.ConnectWalletButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 9.375rem;
  height: 2.25rem;
  margin-right: 1.25rem;
  border: 0.125rem solid #2C3131;
  box-sizing: border-box;
  box-shadow: 0.0625rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.25);
  flex: none;
  order: 0;
  flex-grow: 0;
  flex-shrink: 0;
}
.ConnectWalletButton:hover {
  cursor: pointer;
}
.ConnectWalletButton:active {
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.ConnectWalletText {
  position: static;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5rem;
  text-align: center;
  color: #2C3131;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0rem 0.625rem;
}
#BackButton {
  border-left: 0.0625rem solid #C1BDBC;
  border-right: 0.0625rem solid #C1BDBC;
}
#BackButton:active {
  border-left: 0.1875rem solid #C1BDBC;
}
#ForwardButton {
  border-right: 0.0625rem solid #C1BDBC;
}


.SearchBar {
  position: absolute;
  width: 100%;
  height: 2.25rem;
  left: 0rem;
  top: 7.8125rem;
  background-color: #EDE8E7;
  border: 0.0625rem solid #C1BDBC;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.SearchButton {
  width: 9.375rem;
  height: 2.0625rem;
  box-sizing: border-box;
  border-bottom: 0.125rem solid #C1BDBC;
  border-right: 0.0625rem solid #C1BDBC;
  border-top: 0rem solid #C1BDBC;
  border-left: 0.0625rem solid #C1BDBC;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.75rem;
  text-align: center;
  color: #2C3131;
  flex-shrink: 0;
}
.SearchButton:hover {
  cursor: default;
}
.SearchRectangle {
  width: 62.5rem;
  height: 2.0625rem;
  box-sizing: border-box;
  background: #EEEEEE;
  border: 0.125rem solid #2C3131;
  border-top: 0.0625rem solid #2C3131;
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.0938rem 0.125rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}
.SearchRectangle:hover {
  cursor: text;
}
.SearchText {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  color: #2C3131;
  margin-right: auto;
  margin-left: 0.625rem;
}
.MagButton {
  width: 1.3125rem;
  height: 1.3125rem;
  margin-right: 0.375rem;
  border: 0.0938rem solid #2C3131;
  box-sizing: border-box;
  box-shadow: 0.0625rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.MagButton:hover {
  cursor: default;
}
.MagVector {
  width: 0.875rem;
  height: 0.875rem;
}



.HeroContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  left: 0rem;
  border: 0.0625rem solid #C1BDBC;
  box-sizing: border-box;
  background-color: #EDE8E7;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.GlobeGif {
  position: absolute;
  width: 10.3125rem;
  height: 10.3125rem;
  top: 12.8125rem;
}
.MainLogo {
  position: absolute;
  width: 23.75rem;
  top: 22.1875rem;
  /*background: linear-gradient(90deg, #6427E7 0%, #8177D6 100%);*/
  /*background-color: #EDE8E7;*/
  /*filter: invert(100%);*/
}
.JoinWaitlistButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.125rem 2.5rem;

  position: absolute;
  width: 15.625rem;
  height: 3.3125rem;
  top: 30rem;

  background: #6427E7;
  border: 0.125rem solid #2C3131;
  box-sizing: border-box;
  box-shadow: 0.125rem 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}
.JoinWaitlistButton:hover {
  cursor: pointer;
}
.JoinWaitlistButton:active {
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.JoinWaitlistText {
  position: static;
  height: 1.5rem;
  top: 1.125rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: center;
  color: #EDE8E7;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0rem 0.625rem;
  text-align: center;
}
.ViewLeaderboardText {
  position: absolute;
  top: 37.5rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  color: #2C3131;
}
.DescText {
  position: absolute;
  top: 34.5rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  color: #2C3131;
}
.WaitlistHeaderContainer {
  position:absolute;
  width: 95%;
  top: 9.375rem;
  height: 6.5625rem;
  border-bottom: 0.0938rem solid #C1BDBC;
  display: flex;
  justify-content: left;
  flex-direction: column;
}
.WaitlistTitle {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  color: #2C3131;
  margin-top: 1.25rem;
  padding-left: 0.625rem;
}
.WaitlistSubtitle {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  color: #2C3131;
  margin-top: 0.9375rem;
  padding-left: 0.9375rem;
  white-space: nowrap;
}
.ToggleContainer {
  margin-top:17.1875rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.WaitlistToggleUnclicked {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 9.375rem;
  height: 2.25rem;
  border: 0.125rem solid #2C3131;
  box-sizing: border-box;
  box-shadow: 0.125rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.25);
  background-color: #D4CFCE;
  margin:0.5rem;
}
.WaitlistToggleUnclicked:hover {
  cursor: pointer;
}
.WaitlistToggleUnclicked:active {
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.WaitlistToggleClicked {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 9.375rem;
  height: 2.25rem;
  border: 0.125rem solid #2C3131;
  box-sizing: border-box;
  background-color: #D4CFCE;
  margin:0.5rem;
}
.WaitlistToggleText {
  position: static;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  color: #2C3131;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0rem 0.625rem;
}
.WaitlistForm {
  position: absolute;
  top: 19.6875rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}
.FormLabel {
  width: 57rem;
  height: 2.375rem;
  left: 4.125rem;
  margin-top:2.5rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  color: #2C3131;
  margin-left: 3.125rem;
}
.FormLabelCheckbox {
  height: 2.375rem;
  left: 4.125rem;
  width: 12.5rem;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  color: #2C3131;
  margin-left: 3.125rem;
  margin-top:-0.625rem;
  margin-bottom:0.625rem;
  display: flex;
  align-items: center;
}
.FormInput {
  width: 43.75rem;
  height: 2.0625rem;
  box-sizing: border-box;
  background: #EEEEEE;
  border: 0.125rem solid #2C3131;
  border-top: 0.0625rem solid #2C3131;
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.0938rem 0.125rem rgba(0, 0, 0, 0.5);
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  color: #2C3131;
  padding-left: 0.625rem;
  margin-top: 0.375rem;
}
.FormInputNumber {
  width: 12.5rem;
  height: 2.0625rem;
  box-sizing: border-box;
  background: #EEEEEE;
  border: 0.125rem solid #2C3131;
  border-top: 0.0625rem solid #2C3131;
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.0938rem 0.125rem rgba(0, 0, 0, 0.5);
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  color: #2C3131;
  padding-left: 0.625rem;
  margin-top: 0.375rem;
}
.FormInputCheckbox {
  width: 1.25rem;
  height: 1.25rem;
  box-sizing: border-box;
  background: #EEEEEE;
  border: qpx solid #2C3131;
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.0938rem 0.125rem rgba(0, 0, 0, 0.5);
  padding-left: 0.625rem;
  margin-right: 0.5rem;
}
.FormInput:focus {
  outline: none
}
.FormSubmit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 9.375rem;
  height: 2.25rem;
  border: 0.125rem solid #2C3131;
  box-sizing: border-box;
  background-color: #D4CFCE;
  margin: auto;
  margin-top:1.5625rem;
  box-shadow: 0.125rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.25);
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  color: #2C3131;
}
.CopyReferral {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 11.25rem;
  height: 2.25rem;
  border: 0.125rem solid #2C3131;
  box-sizing: border-box;
  background-color: #D4CFCE;
  margin: auto;
  margin-top:1.5625rem;
  box-shadow: 0.125rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.25);
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  color: #2C3131;
}
.FormSubmit:hover {
  cursor: pointer;
}
.FormSubmit:active {
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.CopyReferral:hover {
  cursor: pointer;
}
.CopyReferral:active {
  box-sizing: border-box;
  box-shadow: inset 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.1);
}
.ConstructionIcon {
  position: absolute;
  width: 6.25rem;
  height: 6.25rem;
  top: 15.625rem;
}
.Subtitle {
  position: absolute;
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #2C3131;
  top: 25rem;
}
.DinoGame {
  position: absolute;
  width: 62.5rem;
  height: 21.875rem;
  top: 7.5rem;
  padding-top: 3.125rem;
  border: 0.0625rem solid white;
  box-sizing: border-box;
}
.SpaceText {
  font-family: 'Space Mono';
  font-weight: 300;
  font-size: 1.125rem;
  color: white;
  margin-top: 31.25rem;
}
.ClosedApp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ReferralPage {
  top: 17.5rem;
  left: 9.375rem;
  width: 62.5rem;
  position: absolute;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
}
.SubtitleFlex {
  font-family: 'Space Mono';
  font-style: bold;
  font-weight: bold;
  font-size: 1.125rem;
  color: #2C3131;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.SubSubtitleFlex {
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  color: #2C3131;
  margin-top: 0.625rem;
}
.TopContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.StatsContainer {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  margin-left: 6.25rem;
  margin-top: -9.375rem;
}
.LeaderboardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 21.875rem;
  height: 18.75rem;
  border: 0.125rem solid white;
  box-sizing: border-box;
  background-color: black;
  margin-top:-5rem;
  box-shadow: 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.25);
  font-family: 'Space Mono';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  color: #52FF33;
  padding: 0.625rem;
  padding-top:0.625rem;
  overflow-y:scroll;
}
.LeaderboardGridContainer {
  margin-top: 0.75rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.625rem;
  text-align: center;
}
.LeaderboardHeader {
  margin-top: 0.3125rem;
  padding-left: 0rem;
  padding-right: 0rem;
}
.LeaderboardText {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.TwitterLink {
  color: #2C3131;
}